import NPLButton from '@/components/npl/NPLButton';
import Icon from '@/components/npl/Icon';
import { t } from '@/utility/localization';

export const PendingApplicationApprovalModal = ({
  title = '', // can pass custom title. needed for Event approval
  withRefundNote,
  onClose
}) => {
  const renderMessage = (icon, message) => {
    return (
      <div className="flex space-x-8">
        <Icon
          name={icon}
          width={20}
          height={20}
          fill="#1B1B18"
          fillOpacity={0.5}
        />
        <p className="text-body-md text-dark-1b">{message}</p>
      </div>
    );
  };
  return (
    <div className="px-24 py-40 lg:px-40">
      <div className="flex h-64 w-64 items-center justify-center rounded-full bg-npl-neutral-light-solid-3">
        <Icon name="hourglass-01" height={32} width={32} fill="#91918D" />
      </div>
      <h2 className="mt-16 text-heading-md font-semibold text-dark-1b">
        {title || t('your-membership-is-pending-approval-0')}
      </h2>
      <div className="mt-16 space-y-16">
        {renderMessage(
          'mail-01',
          t('you-will-be-notified-via-email-upon-approval')
        )}
        {withRefundNote
          ? renderMessage(
              'currency-dollar-circle',
              'If rejected, you will be fully refunded.'
            )
          : null}
      </div>
      <div className="mt-32">
        <NPLButton
          hierarchy="accent_primary"
          stretch
          size={'lg'}
          onClick={onClose}
          buttonText={t('got-it')}
        />
      </div>
    </div>
  );
};
